import AutoBind from 'auto-bind'

let el
let input
let label
let labelValue

export default class InputFile {

    constructor (element) {
        AutoBind(this)

        if(element) {
            this.el = element
            this.input = this.el.querySelector('input')
            this.label = this.el.querySelector('[data-input-file-value]')
            this.labelValue = this.el.querySelector('[data-input-file-value]').innerHTML
            this.init()
        }
    }

    init() {
        this.input.addEventListener('change', ev => {
            let fileName = ''
            fileName = ev.target.value.split('\\').pop()

            if( fileName ) {
                this.label.innerHTML = fileName
            }else{
                this.label.innerHTML = this.labelValue
            }
        })
    }
}
