import AutoBind from 'auto-bind'

let card
let details
let closeBtn

export default class TeamMemberCard {

    constructor (element) {
        AutoBind(this)

        this.card = element
        this.details = element.querySelector('[data-team-member-details]')
        this.closeBtn = element.querySelector('[data-team-member-details-close]')

        this.init()
    }

    init() {
        this.card.addEventListener('click', this.displayDetails)
        this.closeBtn.addEventListener('click', this.removeDetails)
    }

    displayDetails(ev) {
        this.card.classList.add('has-details-open')
    }

    removeDetails(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        this.card.classList.remove('has-details-open')
    }
}
