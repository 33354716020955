import AutoBind from 'auto-bind'
import Toggle from 'toggle-all'
import { lock, unlock } from 'tua-body-scroll-lock'

export default class MobileMenu {
    constructor (element) {
        AutoBind(this)

        this.element = document.querySelector(element)

        this.init()
    }

    init() {
        const toggleNav = new Toggle({
            selectorToggle: '[data-toggle-nav]',
            toggleActiveClass: 'is-active',
            callbackToggle: this.onToggle
        })
    }

    onToggle() {
        if (this.element.classList.contains('is-active')) {
            // Menu is closed
            unlock(this.element)
        } else {
            // Menu is opened
            lock(this.element)
        }
    }
}
