import AutoBind from 'auto-bind'
import SmoothScroll from 'smooth-scroll'

let urlParams
let el
let tabs
let items
let itemIndex
let scrollTo

export default class Tabs {

    constructor (element) {
        AutoBind(this)

        this.el = element

        if(this.el) {
            this.init()
        }
    }

    init() {
        this.tabs = Array.from(this.el.querySelectorAll('[data-tab]'))
        this.items = Array.from(this.el.querySelectorAll('[data-tab-item]'))

        this.tabs.forEach((item) => {
            item.addEventListener('click', this.handleTabs)
        })

        this.scrollTo = new SmoothScroll()

        this.handleTabs()
    }

    handleTabs(ev) {
        if(ev == undefined) {
            this.urlParams = new URLSearchParams(window.location.search)

            if(this.urlParams.get('type')) {
                this.items.forEach((item, index) => {
                    if(this.urlParams.get('type') == item.dataset.tabItem) {
                        this.itemIndex = index
                    }
                })
            }else{
                this.itemIndex = 0
            }
        }else{
            ev.preventDefault()
            this.itemIndex = this.tabs.indexOf(ev.currentTarget)
        }

        this.tabs.forEach((item) => {
            item.classList.remove('is-active')
        })
        this.tabs[this.itemIndex].classList.add('is-active')

        this.items.forEach((item) => {
            item.classList.remove('is-active')
        })
        this.items[this.itemIndex].classList.add('is-active')

        if((ev != undefined) || this.urlParams.get('type')) {
            this.scrollTo.animateScroll(document.querySelector('#services'), null, {
                offset: 110,
                speed: 1200
            })
        }
    }
}
