import AutoBind from 'auto-bind'

let el
let mediaQuery

export default class FooterAccordion {

    constructor (element) {
        AutoBind(this)

        this.el = element
        this.mediaQuery = window.matchMedia('(min-width: 992px)')

        if(this.el) {
            this.init()
        }
    }

    init() {
        this.handleState(this.mediaQuery)

        this.mediaQuery.addEventListener('change', this.handleState)
    }

    handleState(ev) {
        if(!ev.matches) { // Mobile
            this.el.dataset.footerAccordion = 'mobile'

            let items = Array.from(this.el.querySelectorAll('[aria-expanded]'))

            items.forEach((item) => {
                item.addEventListener('click', this.toggleItem)
            })
        }else{ //Desktop
            this.el.dataset.footerAccordion = 'desktop'

            let items = Array.from(this.el.querySelectorAll('[aria-expanded]'))

            items.forEach((item) => {
                item.removeEventListener('click', this.toggleItem)
            })
        }
    }

    toggleItem(ev) {
        if(ev.currentTarget.getAttribute('aria-expanded') == 'true') {
            ev.currentTarget.setAttribute('aria-expanded', 'false')
        }else{
            ev.currentTarget.setAttribute('aria-expanded', 'true')
        }
    }
}
