import Swiper, { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

export default class TestimonialsCarousel {
    constructor (element) {
        Swiper.use([Pagination, Autoplay])

        let swiper = new Swiper(element, {
            autoplay: {
                delay: 3000
            },
            speed: 1200,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
        });
    }
}
