import AutoBind from 'auto-bind'

let wrapper
let header
let offset

export default class StickyHeader {

    constructor (wrapper, header) {
        AutoBind(this)

        this.wrapper = wrapper

        this.header = header

        this.init()
    }

    init() {
        this.offset = this.header.offsetTop

        this.toggleSticky()

        window.addEventListener('scroll', this.toggleSticky)
    }

    toggleSticky() {
        if (window.pageYOffset >= this.offset) {
            this.wrapper.classList.add('has-sticky-header')
        } else {
            this.wrapper.classList.remove('has-sticky-header')
        }
    }
}
