document.documentElement.classList.remove('no-js');

import DesktopMenu from './components/DesktopMenu'
import MobileMenu from './components/MobileMenu'
import StickyHeader from './components/StickyHeader'
import TestimonialsCarousel from './components/TestimonialsCarousel'
import TeamMemberCard from './components/TeamMemberCard'
import Tabs from './components/Tabs'
import ShareLocation from './components/ShareLocation'
import FooterAccordion from './components/FooterAccordion'
import InputFile from './components/InputFile'
import Aos from 'aos'
import 'aos/dist/aos.css'

Aos.init({
    disable: window.innerWidth < 992
})

const stickyHeader = new StickyHeader(document.querySelector('.c-wrapper'), document.querySelector('[data-sticky-header]'))

const desktopMenu = new DesktopMenu(document.querySelector('[data-desktop-menu]'))

const mobileMenu = new MobileMenu('[data-mobile-menu]')

const testimonials = new TestimonialsCarousel(document.querySelector('[data-testimonials-carousel]'))

const teamMembers = Array.from(document.querySelectorAll('[data-team-member'))
teamMembers.forEach((item) => {
    let member = new TeamMemberCard(item)
})

const servicesTabs = new Tabs(document.querySelector('[data-tabs]'))

const shareLocation = new ShareLocation(document.querySelector('[data-share]'))

const footerAccordion = new FooterAccordion(document.querySelector('[data-footer-accordion'))

const inputFiles = Array.from(document.querySelectorAll('[data-input-file]'))

inputFiles.forEach((item) => {
    let inputFile = new InputFile(item)
})
