import AutoBind from 'auto-bind'

let el
let items

export default class DesktopMenu {

    constructor (element) {
        AutoBind(this)

        if(element) {
            this.el = element
            this.init()
        }
    }

    init() {
        this.items = Array.from(this.el.querySelectorAll('[data-desktop-menu-item]'))

        this.items.forEach((item) => {
            item.addEventListener('mouseover', ev => {
                this.handleMouseOverItems(item)
            })

            item.addEventListener('mouseout', ev => {
                this.handleMouseOutItems(item)
            })
        })
    }

    handleMouseOverItems(link) {
        this.items.forEach((item) => {
            if(link != item) {
                item.classList.add('is-lighter')
            }
        })
    }

    handleMouseOutItems(link) {
        this.items.forEach((item) => {
            item.classList.remove('is-lighter')
        })
    }
}
